@mixin arrow($width) {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  display: inline-block;
  border-width: $width;
  z-index: 101;
}

@mixin arrow-top($color) {
  border-color: transparent transparent $color transparent;
}

@mixin arrow-bottom($color) {
  border-color: $color transparent transparent transparent;
}

@mixin arrow-left($color) {
  border-color: transparent $color transparent transparent;
}

@mixin arrow-right($color) {
  border-color: transparent transparent transparent $color;
}

@mixin backdrop-blur($blur) {
  backdrop-filter: blur($blur);
}

@mixin backdrop-shadow() {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--av-backdrop-color);
  z-index: 100;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin form-input() {
  background: var(--av-form-input-background-color);
  border-radius: var(--av-form-input-border-radius);
  padding: 7px 10px;
  border: none;
  color: var(--av-form-input-color);
  box-sizing: border-box;
  width: 100%;
  &:disabled {
    opacity: 0.6;
  }
  &::placeholder {
    opacity: 0.7;
    color: var(--av-form-input-color);
  }

  &:focus-visible {
    outline-color: var(--av-color-focus-outline);
  }
  &::ng-deep option {
    color: var(--av-font-color-dark);
  }
}

@mixin chevron() {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-right: 2px solid var(--av-card-color);
  border-top: 2px solid var(--av-card-color);
  transform: rotate(135deg);
  transform-origin: center;
  transition: transform 0.2s ease-out, top 0.2s ease-out;
}

@mixin general-box-shadow() {
  box-shadow: var(--av-box-shadow-container);
}

@mixin animate($name, $duration, $count, $timing) {
  -webkit-animation-name: $name;
  -webkit-animation-duration: $duration;
  -webkit-animation-iteration-count: $count;
  -webkit-animation-timing-function: $timing;
  -moz-animation-name: $name;
  -moz-animation-duration: $duration;
  -moz-animation-iteration-count: $count;
  -moz-animation-timing-function: $timing;
  animation-name: $name;
  animation-duration: $duration;
  animation-iteration-count: $count;
  animation-timing-function: $timing;
}

@mixin rings($duration, $delay) {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -8px;
  left: -8px;
  right: 0;
  bottom: 0;
  content: "";
  height: 100%;
  width: 100%;
  border: 8px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  animation-name: ripple;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  z-index: -1;
}

@mixin darken-on-hover() {
  &:after {
    background-color: rgba(0, 0, 0, 0.12);
    position: absolute;
    opacity: 0;
    visibility: hidden;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    transition: opacity 0.2s ease-out;
  }
  &:hover,
  &:focus {
    position: relative;
    &:after {
      opacity: 1;
      visibility: visible;
    }
  }
  &:active {
    position: relative;
    &:after {
      background-color: rgba(0, 0, 0, 0.2);
      opacity: 1;
      visibility: visible;
    }
  }
}

@mixin actions-container-blur() {
  background: rgba(255, 255, 255, 0.2);
  padding: 4px;
  border-radius: 22px;
  backdrop-filter: blur(10px);
}
